

























import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class Gallery extends Vue {
  imgList: string[] = [
    "25_stan.jpg",
    "26_stan.jpg",
    "27_stan.jpg",
    "28_stan.jpg",
    "29.jpg",
    "31_stan.jpg",
    "101.jpg",
    "103.jpg",
    "104.jpg",
    "_dsc8130.jpg",
    "_dsc8138.jpg",
    "_dsc8146.jpg",
    "_dsc8147.jpg",
    "_dsc8149.jpg",
    "_dsc8155.jpg",
    "_dsc8158.jpg",
    "_dsc8162.jpg",
    "_dsc8164.jpg",
    "_dsc8167.jpg",
    "_dsc8168.jpg",
    "_dsc8169.jpg",
    "_dsc8174.jpg",
    "_dsc8179.jpg",
    "_dsc8180.jpg",
    "_dsc8181.jpg",
    "_dsc8182.jpg",
  ];

  get swiperOptions(): any {
    return {
      slidesPerView: "auto",
      spaceBetween: this.$vuetify.breakpoint.smAndDown ? 10 : 30,
      grabCursor: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  }
}
